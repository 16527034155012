/**
 * firmware
 */

import Vue from 'vue';
import * as types from './mutation-types';
import * as urls from '../config/urls';
import * as helpers from '../utils/helpers';

export default {
    namespaced: true,

    state: {

        firmwarePlainList: [],
        firmwarePlainLoading: false,

        firmwareEncryptionList: [],
        firmwareEncryptionLoading: false,
    },

    mutations: {
        [types.UPDATE_LIST](state, { type, list }) {
            state[`${type}List`] = list;
        },
        [types.UPDATE_DETAIL](state, { type, detail }) {
            state[`${type}Detail`] = detail;
        },
        [types.UPDATE_PAGE_SIZE](state, { type, size }) {
            state[`${type}PageSize`] = size;
        },
        [types.UPDATE_CURRENT_PAGINATION](state, { type, pagination }) {
            state[`${type}Pagination`] = pagination;
        },
        [types.UPDATE_FILTERS](state, { type, filters }) {
            state[`${type}Filters`] = filters;
        },
        [types.UPDATE_LOADING_STATUS](state, { type, isLoading }) {
            state[`${type}Loading`] = isLoading;
        },
    },

    actions: {
        getFirmwarePlainList({ commit }) {
            const type = 'firmwarePlain';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_PLAIN_FIRMWARE_LIST).then((response) => {
                    console.log('res', response);
                    const list = response?.data || [];
                    commit(types.UPDATE_LIST, { type, list });
                    // commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        getFirmwareEncryptionList({ commit }) {
            const type = 'firmwareEncryption';
            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_ENCRYPTION_FIRMWARE_LIST).then((response) => {
                    const list = response?.data || [];
                    commit(types.UPDATE_LIST, { type, list });
                    // commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        generateKey() {
            return new Promise((resolve, reject) => {
                Vue.http.get(urls.GENERATE_FIRMWARE_KEY, {
                    responseType: 'blob',
                }).then((response) => {
                    const [, fileName] = (response.headers['content-disposition'] || '').split('filename=');
                    const blob = response.data;
                    const url = URL.createObjectURL(blob);
                    if (fileName) {
                        const aLink = document.createElement('a');
                        aLink.href = url;
                        aLink.download = decodeURI(fileName);
                        aLink.click();
                    } else {
                        window.location.assign(url);
                    }
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        createEncryptFirmware(_, { filename, privkey } = {}) {
            // const type = 'firmwareEncryption';
            // const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                Vue.http.post(urls.ENCRYPT_FIRMWARE, {
                    filename,
                    privkey,
                }).then((response) => {
                    // dispatch(`get${Type}List`);
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        createSignature(_, { rcid, privkey } = {}) {
            // const type = 'firmwareEncryption';
            // const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                Vue.http.post(urls.CREATE_SIGNRC, {
                    rcid,
                    privkey,
                }, { responseType: 'blob' }).then((response) => {
                    // dispatch(`get${Type}List`);
                    const [, fileName] = (response.headers['content-disposition'] || '').split('filename=');
                    const blob = response.data;
                    const url = URL.createObjectURL(blob);
                    if (fileName) {
                        const aLink = document.createElement('a');
                        aLink.href = url;
                        aLink.download = decodeURI(fileName);
                        aLink.click();
                    } else {
                        window.location.assign(url);
                    }
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        deleteEncryptionFirmware(_, { FileName = '' } = {}) {
            const url = helpers.buildURL(urls.DELETE_ENCRYPTION_FIRMWARE, FileName);
            return new Promise((resolve, reject) => {
                Vue.http.delete(url).then((response) => {
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },


        verifyLog(_, { log, privkey } = {}) {
            const formData = new FormData();
            formData.append('log', log);
            formData.append('privkey', privkey);

            return new Promise((resolve, reject) => {
                Vue.http.post(urls.VERIFY_LOG, formData, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                }).then((response) => {
                    // dispatch(`get${Type}List`);
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        verifyFirmware(_, { firmware, privkey } = {}) {
            const formData = new FormData();
            formData.append('firmware', firmware);
            formData.append('privkey', privkey);

            return new Promise((resolve, reject) => {
                Vue.http.post(urls.VERIFY_FIRMWARE, formData, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                }).then((response) => {
                    // dispatch(`get${Type}List`);
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },
    },
};
