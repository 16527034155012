<template>
    <el-menu v-bind="$attrs" v-on="$listeners">
        <el-menu-item index="/workbench" v-if="showWorkbench && isPassed && showMenu('Workbench')">
            <i class="iconfont icon-workbench"></i>
            <span slot="title">{{ $t('menu.overview') }}</span>
        </el-menu-item>
        <el-menu-item index="/monitor_manage/monitor" v-if="isPassed && showMenu('MonitorView')">
            <i class="iconfont icon-monitor"></i>
            <span slot="title">{{ $t('menu.monitor') }}</span>
        </el-menu-item>
        <el-menu-item index="/data_stat_manage/data-stat-list" v-if="isPassed && showMenu('DataStatList')">
            <i class="iconfont icon-stat"></i>
            <span slot="title">{{ $t('menu.data_stat') }}</span>
        </el-menu-item>
        <el-submenu index="/business_manage" v-if="isPassed && showMenuGroup(['DroneList'])">
            <template slot="title">
                <i class="iconfont icon-yewu"></i>
                <span>{{ $t('menu.business_management') }}</span>
            </template>
            <el-menu-item index="/business_manage/no-fly-zone-list" v-if="showMenu('NoFlyZoneList')">{{ $t('menu.no_fly_zone') }}</el-menu-item>
            <el-menu-item index="/business_manage/drone-list" v-if="showMenu('DroneList')">{{ $t('menu.drone_management') }}</el-menu-item>
            <el-menu-item index="/business_manage/block-list" v-if="showMenu('BlockList')">{{ $t('menu.field_management') }}</el-menu-item>
            <el-menu-item index="/business_manage/group-control" v-if="showMenu('GroupControl')">{{ $t('menu.group_control') }}</el-menu-item>
            <el-menu-item index="/business_manage/unified-task-list" v-if="showMenu('UnifiedTaskList')">{{ $t('menu.unified_task') }}</el-menu-item>
            <!--<el-menu-item index="4-2">其他设备</el-menu-item>
            <el-menu-item index="4-3">部件维护</el-menu-item>-->
        </el-submenu>
        <el-submenu index="/institution_manage" v-if="showMenuGroup(['AccountDetail', 'MemberList', 'SystemLogList','SubCompanyList'])">
            <template slot="title">
                <i class="iconfont icon-enterprise"></i>
                <span>{{ $tc('menu.company', isPersonalOperator + 1) }}</span>
            </template>
            <el-menu-item index="/institution_manage/account-detail" v-if="showMenu('AccountDetail')">{{ $tc('menu.information', isPersonalOperator + 1) }}</el-menu-item>
            <el-menu-item index="/institution_manage/sub-company-list" v-if="showMenu('SubCompanyList') && hasSubAccount">
                {{ $t('menu.subordinate_company') }}
            </el-menu-item>
            <el-menu-item index="/institution_manage/member-list" v-if="isPassed && showMenu('MemberList') && !isPersonalOperator">{{ $t('menu.staffs') }}</el-menu-item>
            <!--<el-menu-item index="/institution_manage/order-list" v-if="isPassed && showMenu('OrderList')">{{ $t('menu.orders') }}</el-menu-item>-->
            <!--<el-menu-item index="/institution_manage/invoice-list" v-if="isPassed && showMenu('InvoiceList')">{{ $t('menu.invoices') }}</el-menu-item>-->
            <el-menu-item index="/institution_manage/team-list" v-if="isPassed && showMenu('TeamList') && !isPersonalOperator">{{ $t('menu.teams') }}</el-menu-item>
            <!--<el-menu-item index="6-2">团队管理</el-menu-item>
            <el-menu-item index="6-2">我的团队</el-menu-item>
            <el-menu-item index="6-2">任务管理</el-menu-item>
            <el-menu-item index="6-2">我的任务</el-menu-item>-->
            <el-menu-item index="/institution_manage/system-log-list" v-if="isPassed && showMenu('SystemLogList')">{{ $t('menu.system_log') }}</el-menu-item>
        </el-submenu>
        <el-submenu index="/bulletin_manage" v-if="isPassed && showMenuGroup(['BulletinList'])">
            <template slot="title">
                <i class="iconfont icon-notice"></i>
                <span>{{ $t('menu.announcement_management') }}</span>
            </template>
            <el-menu-item index="/bulletin_manage/bulletin-list" v-if="showMenu('BulletinList')">{{ $t('menu.announcement') }}</el-menu-item>
        </el-submenu>
        <el-menu-item @click="onShowSettings">
            <i class="iconfont icon-settings"></i>
            <span slot="title">{{ $t('settings.label') }}</span>
        </el-menu-item>
        <el-menu-item @click="handleLogout" v-if="showLogout">
            <i class="iconfont icon-pwoer-off"></i>
            <span slot="title">{{ $t('menu.sign_out') }}</span>
        </el-menu-item>
    </el-menu>
</template>

<script>
    import { mapState, mapGetters } from 'vuex';
    import logoutMixin from '../../mixins/logoutMixin';
    import settingMixin from '../../mixins/settingMixin';

    export default {
        name: 'AccountMenu4',

        mixins: [logoutMixin, settingMixin],

        props: {
            showLogout: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            ...mapGetters('user', ['showMenu', 'showMenuGroup', 'isPassed', 'isPersonalOperator', 'hasSubAccount']),
            ...mapState('theme', ['showWorkbench']),
            ...mapState('user', ['userInfo']),
        },
    };
</script>

<style lang="scss" scoped>
</style>
