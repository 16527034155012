/**
 * 辅助函数
 */
import gcoord from 'gcoord';
import moment from 'moment';
import store from '../store';

export function buildURL(uri, ...argv) {
    return uri.replace(/{\w+}/g, () => {
        const res = argv.shift();
        if (res === undefined) {
            throw new Error('URI 参数不足');
        }
        return res;
    });
}

/**
 * 更新对象
 * 将source对象里的数据覆盖到target对象里（但只覆盖target已有数据）
 * 返回新对象
 */
export function update(target, source) {
    const result = { ...target };
    Object.keys(target).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
            result[key] = source[key];
        }
    });
    return result;
}

/**
 * 清除对象中value为空字符的key
 * 返回新对象
 */
export function clearKeys(obj) {
    const newObj = Object.assign({}, obj);
    Object.keys(newObj).forEach((key) => {
        if (newObj[key] === '') {
            delete newObj[key];
        }
    });
    return newObj;
}

/**
 * 转换对象某些key对应的值到指定类型
 * 其中keys是对象中的某些key构成的数组、toType是转换函数
 */
export function typeConversionByKeys(targetObject, keys, toType) {
    const result = { ...targetObject };
    Object.keys(targetObject).forEach((key) => {
        if (keys.indexOf(key) >= 0) {
            result[key] = toType(result[key]);
        }
    });
    return result;
}

/**
 * 选取原object指定属性，构成新的object
 * @param originObject
 * @param pickKeys
 */
export function pick(originObject, pickKeys = []) {
    const newObject = {};
    pickKeys.forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(originObject, key)) {
            newObject[key] = originObject[key];
        }
    });
    return newObject;
}

/**
 * 等待异步结果
 * @param checker 检测函数，返回值为truthy时表示得到想要的结果，否则反之
 * @param timeout 指定时间内没有得到想要的结果则超时
 */
export function until(checker = () => true, timeout = 2000) {
    return new Promise((resolve) => {
        let pollingTimer = null;

        const timeoutTimer = setTimeout(() => {
            clearInterval(pollingTimer);
            resolve(false);
        }, timeout);

        pollingTimer = setInterval(() => {
            const result = checker();
            if (result) {
                clearTimeout(timeoutTimer);
                clearInterval(pollingTimer);
                resolve(result);
            }
        }, 10);
    });
}

/**
 * 将总秒数格式化为“x时y分z秒”形式
 * @param sec
 * @param isChinese
 * @returns {string}
 */
export function formatSec(sec, isChinese = false) {
    const { HOUR, MINUTE, SECOND } = isChinese ? { HOUR: '时', MINUTE: '分', SECOND: '秒' } : { HOUR: 'h', MINUTE: 'min', SECOND: 's' };
    let remainingSec = sec;
    const hours = Math.floor(remainingSec / 3600);

    remainingSec -= hours * 3600;
    const minutes = Math.floor(remainingSec / 60);

    remainingSec -= minutes * 60;

    return `${hours ? `${hours}${HOUR}` : ''}${minutes ? `${minutes}${MINUTE}` : ''}${remainingSec ? `${remainingSec}${SECOND}` : ''}`;
}

export const formatTime = (timestamp, format = 'YYYY/MM/DD HH:mm:ss') => (moment(timestamp).isValid() ? moment(timestamp).format(format) : '-');

export const toFixed = (num, digits = 2) => (Number(num).toFixed(digits)) - 0;

export const falsyTo = (fv, to) => (fv || fv === 0 ? fv : to);

const chinaRegions = [
    [49.220400, 79.446200, 42.889900, 96.330000],
    [54.141500, 109.687200, 39.374200, 135.000200],
    [42.889900, 73.124600, 29.529700, 124.143255],
    [29.529700, 82.968400, 26.718600, 97.035200],
    [29.529700, 97.025300, 20.414096, 124.367395],
    [20.414096, 107.975793, 17.871542, 111.744104],
];

const chinaRegionsExclude = [
    [25.398623, 119.921265, 21.785006, 122.497559],
    [22.284000, 101.865200, 20.098800, 106.665000],
    [21.542200, 106.452500, 20.487800, 108.051000],
    [55.817500, 109.032300, 50.325700, 119.127000],
    [55.817500, 127.456800, 49.557400, 137.022700],
    [44.892200, 131.266200, 42.569200, 137.022700],
];

function insideRect(rect, lng, lat) {
    const [north, west, south, east] = rect;
    return (lat < north) && (lat > south) && (lng > west) && (lng < east);
}

function isInChina([lng, lat]) {
    /* eslint-disable */
    for (const region of chinaRegions) {
        if (insideRect(region, lng, lat)) {
            for (const exclude of chinaRegionsExclude) {
                if (insideRect(exclude, lng, lat)) {
                    return false;
                }
            }
            return true;
        }
    }
    return false;
    /* eslint-enable */
}

/**
 * 将gps坐标，转换为国测局坐标（如果是英文状态，则不转换）
 *
 * @param point 经纬度构成的数组[经度, 纬度]
 */
export function coordToGCJ(point) {
    const isEn = store.getters['lang/isEn'];
    return isEn || !isInChina(point) ? point : gcoord.transform(point, gcoord.WGS84, gcoord.GCJ02);
}

/**
 * 将国测局坐标，转换为GPS坐标（如果是英文状态，则不转换）
 *
 * @param point 经纬度构成的数组[经度, 纬度]
 */
export function coordToGPS(point) {
    const isEn = store.getters['lang/isEn'];
    return isEn || !isInChina(point) ? point : gcoord.transform(point, gcoord.GCJ02, gcoord.WGS84);
}

/**
 * 将gps坐标，转换为国测局坐标
 * 转换后坐标，必须在中国才会保留
 *
 * @param point 经纬度构成的数组[经度, 纬度]
 */
export function GPS2GCJ(point) {
    const newPoint = gcoord.transform(point, gcoord.WGS84, gcoord.GCJ02);
    return isInChina(newPoint) ? newPoint : point;
}

/**
 * 将国测局坐标，转换为gps坐标
 * 转换钱坐标，必须在中国才会转换
 *
 * @param point 经纬度构成的数组[经度, 纬度]
 */
export function GCJ2GPS(point) {
    return isInChina(point) ? gcoord.transform(point, gcoord.WGS84, gcoord.GCJ02) : point;
}
