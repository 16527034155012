/**
 * Drones
 */

import Vue from 'vue';
import * as types from './mutation-types';
import * as urls from '../config/urls';
import * as helpers from '../utils/helpers';

export default {
    namespaced: true,

    state: {
        droneList: {},
        droneLoading: false,
        dronePagination: 1,
        dronePageSize: 20,
        droneFilters: {
            yyAccountName: '',
            modelName: '',
            zzAccountName: '',
            oprName: '',
            regFlag: '',
            droneId: '',
            droneName: '',
            deviceType: '',
            saleType: '',
            sortParam: undefined,
        },
        droneDetail: {},
        ACTIVE_STATUS_MAP: {
            0: 'debugging',
            1: 'unactivated',
            2: 'activated',
        },
        // droneModel
        droneModelList: {},
    },

    getters: {
        stat(state) {
            const { stat: { total = 0, workArea = 0, zzNum = 0, sortieCount = 0, modelNum = 0 } = {} } = state.droneList;
            return { total, workArea, zzNum, sortieCount, modelNum };
        },

        activeStatusLabel: state => (status) => {
            const { [status]: label } = state.ACTIVE_STATUS_MAP;
            return label || '-';
        },
    },

    mutations: {
        [types.UPDATE_LIST](state, { type, list }) {
            state[`${type}List`] = list;
        },
        [types.UPDATE_DETAIL](state, { type, detail }) {
            state[`${type}Detail`] = detail;
        },
        [types.UPDATE_PAGE_SIZE](state, { type, size }) {
            state[`${type}PageSize`] = size;
        },
        [types.UPDATE_CURRENT_PAGINATION](state, { type, pagination }) {
            state[`${type}Pagination`] = pagination;
        },
        [types.UPDATE_FILTERS](state, { type, filters }) {
            state[`${type}Filters`] = filters;
        },
        [types.UPDATE_LOADING_STATUS](state, { type, isLoading }) {
            state[`${type}Loading`] = isLoading;
        },
    },

    actions: {
        // 获取农机列表
        getDroneList({ commit, state }, pagination = 1) {
            const type = 'drone';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_DRONE_LIST, {
                    params: {
                        currentPage: pagination,
                        pageSize: state[`${type}PageSize`],
                        ...state[`${type}Filters`],
                    },
                }).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data });
                    commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // 更变农机列表分页Size
        changeDroneListPageSize({ commit, dispatch, state }, size) {
            const type = 'drone';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_PAGE_SIZE, { type, size });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        // 更新农机列表过滤条件
        changeDroneListFilters({ commit, dispatch, state }, filters) {
            const type = 'drone';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_FILTERS, { type, filters });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        // 获取农机详情
        getDroneDetail({ commit }, droneId) {
            const type = 'drone';

            return new Promise((resolve, reject) => {
                Vue.http.get(urls.GET_DRONE_DETAIL, {
                    params: {
                        droneId,
                    },
                }).then((response) => {
                    commit(types.UPDATE_DETAIL, { type, detail: response.data });
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // 清空农机详情数据
        clearDroneDetail({ commit }) {
            const type = 'drone';

            commit(types.UPDATE_DETAIL, { type, detail: {} });
        },

        // 更新农机数据
        updateDrone({ dispatch, commit, state }, { droneId, flyModelId, droneName, regMark, rackNo, buyer }) {
            const type = 'drone';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.put(urls.UPDATE_DRONE, { droneId, flyModelId, droneName, regMark, rackNo, buyer }).then((response) => {
                    dispatch(`get${Type}List`, state[`${type}Pagination`]);
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // 编辑农机制造商标识
        updateManufacturerSign({ dispatch, commit, state }, { droneId, zzDroneNum }) {
            const type = 'drone';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                const url = helpers.buildURL(urls.UPDATE_MANUFACTURER_SIGN, droneId);
                Vue.http.put(url, { zzDroneNum }).then((response) => {
                    dispatch(`get${Type}List`, state[`${type}Pagination`]);
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // 更新农机锁定状态
        updateDroneLock({ dispatch, commit, state }, { droneId, opr, password }) {
            const type = 'drone';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.put(urls.UPDATE_DRONE_LOCK, { droneId, opr, password }).then((response) => {
                    dispatch(`get${Type}List`, state[`${type}Pagination`]);
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // 更新农机销售状态
        updateDroneSaleStatus({ dispatch, commit, state }, { droneId, saleType }) {
            const type = 'drone';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                const url = helpers.buildURL(urls.UPDATE_DRONE_SALE_STATUS, droneId);
                Vue.http.put(url, { saleType }).then((response) => {
                    dispatch(`get${Type}List`, state[`${type}Pagination`]);
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // 获取农机型号下拉列表
        getDroneModelList({ commit }) {
            const type = 'droneModel';

            return new Promise((resolve, reject) => {
                Vue.http.get(urls.GET_OWN_DRONE_MODEL_SELECT_LIST).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data });
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // 更新农机类型
        updateDeviceType({ dispatch, state }, { droneId, deviceType, sprayWidth }) {
            const type = 'drone';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                const url = helpers.buildURL(urls.UPDATE_DEVICE_TYPE, droneId);
                Vue.http.put(url, { deviceType, sprayWidth }).then((response) => {
                    dispatch(`get${Type}List`, state[`${type}Pagination`]);
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // end
    },
};
