import Vue from 'vue';
import Vuex from 'vuex';

import loading from './loadingStore';
import user from './userStore';
import drone from './droneStore';
import droneHistory from './droneHistoryStore';
import droneLog from './droneLogStore';
import droneOdd from './droneOddStore';
import droneModel from './droneModelStore';
import account from './accountStore';
import own from './ownStore';
import bulletin from './bulletinStore';
import noFlyZone from './noflyzoneStore';
import dataStat from './dataStatStore';
import statScreen from './statScreenStore';
import monitor from './monitorStore';
import team from './teamStore';
import order from './orderStore';
import invoice from './invoiceStore';
import unifiedTask from './unifiedTaskStore';
import subsidy from './subsidyStore';
import droneStatistics from './droneStatisticsStore';
import rtk from './rtkStore';
import block from './blockStore';
import groupControl from './groupControlStore';
import common from './commonStore';
import theme from './themeStore';
import lang from './langStore';
import setting from './settingStore';
import appLog from './appLogStore';
import firmwareStore from './firmwareStore';
import groundStationStore from './groundStationStore';
import partWhiteList from './partWhiteListStore';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        loading,
        user,
        drone,
        droneHistory,
        droneLog,
        droneOdd,
        droneModel,
        account,
        own,
        bulletin,
        noFlyZone,
        dataStat,
        statScreen,
        monitor,
        team,
        order,
        invoice,
        unifiedTask,
        subsidy,
        droneStatistics,
        rtk,
        block,
        groupControl,
        common,
        theme,
        lang,
        setting,
        appLog,
        firmwareStore,
        groundStationStore,
        partWhiteList,
    },
});
